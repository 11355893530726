/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useState, useEffect, useContext } from 'react'
import { PhoneNumberInput, StyledButton } from '@/components/atoms'
import route_list from '@/constant/routeUrl'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import useAuthQuery from '@/utility/hooks/apiHooks/useAuth'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { removeCookie, setCookie } from '@/utility/helper/cookies'
import DataHandlerContext from '@/utility/context/dataHandler'

const Login = () => {
  const {rememberMe, dispatch}:any = useContext(DataHandlerContext)
	const [countdown, setCountdown] = useState(30)
	const [phoneNumber, setPhoneNumber] = useState(0)
	const [rememebrMe, setRememberMe] = useState(false)
	const { t } = useTranslation('auth')
	const router = useRouter()
	const { startLogin, is_login_loading, login_callback} = useAuthQuery()

	const handleRememberMe = (e: any) => {
		setRememberMe(e.target.checked)

		if(phoneNumber.length >= 10) {
			if(e.target.checked) {
				setCookie('remember_me', phoneNumber, 30)
			} else {
				removeCookie('remember_me')
			}
		}
	}

	useEffect(() => {
		if(!_.isEmpty(login_callback)) {
			handleRememberMe({target: {checked: rememebrMe}})
			toast.success(login_callback.data.message)
			router.push(`${route_list.otp}?phone=62${phoneNumber}`)
		}
	}, [login_callback])

	useEffect(() => {
		const timer = setTimeout(() => {
			if (countdown > 0) {
				setCountdown(countdown - 1)
			}
		}, 1000)
		return () => clearTimeout(timer)
	}, [countdown])

	useEffect(() => {
		console.log(rememberMe)
		setPhoneNumber(rememberMe ? rememberMe?.replaceAll('62', '') : '')
		setRememberMe(rememberMe ? true : false)
	}, [rememberMe])

  return (
		<div className='tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-w-full'>
			<div className='tw-w-full tw-min-h-[500px] tw-flex tw-items-center tw-justify-center tw-flex-col tw-bg-white md:tw-rounded-[40px] tw-rounded-[20px] tw-p-5'>
				<div className='tw-w-full tw-max-w-md tw-flex tw-flex-col'>
					<h1 className='tw-font-bold tw-text-xl tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green'>
						Login
					</h1>
					<span className='tw-mb-6'>{t('auth.login_title')}</span>
					<span className='tw-mb-1'>{t('auth.phone')}</span>
					<PhoneNumberInput onChange={(data) => {
						const phone = data.target.value.replace('+62', '')
						setPhoneNumber(phone)
					}} data={{value: phoneNumber}}/>
          <div className='tw-flex tw-justify-between tw-mt-2'>
            <div>
              <input type="checkbox" name="remember-me" checked={rememebrMe} onChange={handleRememberMe} />
              <label htmlFor="vehicle1" className='tw-ml-2'>{t('auth.remember_me')}</label><br/>
            </div>
            {/* <StyledButton
              className='tw-bg-transparent tw-underline tw-text-black'
              onClick={() => console.log('Retrieve account')}
              title={t('auth.reset_account')}
            /> */}
          </div>
					<StyledButton
						title='Login'
						className={`${phoneNumber.length >= 10 && 'btn-dark-to-light-green-gradient'} tw-bg-gray-300 tw-mt-14 tw-mb-4 tw-text-white tw-w-full tw-text-center tw-font-semibold tw-text-md tw-items-center tw-py-2 tw-rounded-full tw-flex tw-justify-center disabled:tw-bg-gray-300`}
						onClick={() => startLogin(`62${phoneNumber}`)}
            disabled={phoneNumber.length < 10}
					/>
          <div className='tw-text-center'>
            <span>{t('auth.have_no_account')}</span>
            <StyledButton
              className='tw-bg-transparent tw-underline tw-text-light-green tw-ml-1'
              onClick={() => router.push(route_list.register)}
              title={t('auth.register_now')}
            />
          </div>
				</div>
			</div>
		</div>
	)
}

export default Login

export async function getServerSideProps({ locale }: any) {
	return {
		props: {
			...(await serverSideTranslations(locale, ['footer', 'navbar'])),
			// Will be passed to the page component as props
		},
	}
}
