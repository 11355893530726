import { useEffect, useState } from 'react'
import useCustomMutationReactQuery from '../generalHooks/useCustomMutationReactQuery'
import { getUserList, updatePostById } from '../../apiProcess/sampleApi'
import _ from 'lodash'
import { getUserDetail, loginProcess, otpProcess } from '@/utility/apiProcess/auth/login'
import { toast } from 'react-toastify'

const useAuthQuery = () => {
  // login
  const {
		isLoading: is_login_loading,
		return_data: login_callback,
		startFetch: startLogin,
	} = useCustomMutationReactQuery(loginProcess, {
		onError: (error: Error) => console.log(error),
    isStartOnInit: false,
	})

  // otp
  const {
		isLoading: is_otp_loading,
		return_data: otp_callback,
		startFetch: verifyOtp,
	} = useCustomMutationReactQuery(otpProcess, {
		onError: (error: any) => toast.error(error.data.message),
    isStartOnInit: false,
	})

	// get detail user
	const {
		isLoading: is_detail_user_loading,
		return_data: detail_user_callback,
		startFetch: getDataUser,
	} = useCustomMutationReactQuery(getUserDetail, {
		onError: (error: any) => toast.error(error.data.message),
    isStartOnInit: false,
	})


	return {
    startLogin,
    is_login_loading,
    login_callback,
    verifyOtp,
    is_otp_loading,
    otp_callback,
		getDataUser,
		detail_user_callback
	}
}

export default useAuthQuery
