const api_list = {
  sample_api: '/users',
  login: '/api/request-otp',
  verifyOtp: '/api/verify-otp',
  getDetailUser: '/backend/api/v2/profile',
  agendaFormClient: '/events/regist-event',
  upcomingGuestEvents: '/events/guest/upcoming?type=client',
  pastGuestEvents: '/events/guest/passed?type=client',
  eventsDetailGuest: '/events/guest/detail',
  upcomingEvents: '/events/upcoming',
  pastEvents: '/events/passed',
  eventsDetail: '/events/detail',
  career: '/careers',
  careerDetail: '/careers/detail',
  careerField: '/careers/job-field',
  careerLocation: '/careers/location',
  faq: '/faq',
  faqDetail: '/faq/detail',
  press: '/press',
  blog: '/blog',
  myTransaction: '/backend/api/v2/transaction/list',
  kategoriUsaha: '/backend/api/getMasterKategoriUsaha',
  updateProfile: '/backend/api/v2/profile-website',
  getProvince: '/backend/api/getMasterNamaProvince',
  getRegency: '/backend/api/getMasterNamaKota',
  uploadFile: '/uploadFiles',
  seo: '/seo/api',
  step_2: '/backend/api/v2/marketing/find',
  detail_creator: '/backend/api/v2/marketing/creator',
  step_2post: '/backend/api/v2/marketing/creator/save',
  banks_list: '/backend/api/v2/marketing/banks',
  packages: '/backend/api/v2/marketing/package-order',
  step_3post: '/backend/api/v2/marketing/order/payment',
  bypass_user_token: '/backend/api/v2/internal/tokenUser'
};

export default api_list;