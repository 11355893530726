/* eslint-disable react-hooks/exhaustive-deps */
import { getErrorHandlerData } from "@/utility/helper/requestHelper";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

type Options = {
  onError: Function;
  isStartOnInit?: boolean;
}

const useCustomMutationReactQuery = (
  onFetch:any,
  options:Options = { isStartOnInit: false, onError: () => {} }
) => {
  const { isStartOnInit, onError } = options;

  const [return_data, setReturnData] = useState<any>({});
  const [is_short_loading, setIsShortLoading] = useState(false); // minimal loading time become 0,5s

  const result = useMutation(onFetch);
  const { mutate, data:response, isLoading } = result;

  const startFetch = async (param:any = null, onSuccess:Function = () => {}) => {
    setIsShortLoading(true);
    setTimeout(() => {
      setIsShortLoading(false);
    }, 500);

    mutate(param, {
      onError: (err) => {
        const erroData = getErrorHandlerData(err);
        onError(erroData);
      },
      onSuccess: (data) => onSuccess(data),
    });
  };

  useEffect(() => {
    if (!isStartOnInit) return;

    startFetch();
  }, []);

  useEffect(() => {
    if (response == null) return;
    setReturnData(response);
  }, [response]);

  return {
    ...result,
    isLoading: isLoading || is_short_loading,
    return_data,
    startFetch,
  };
};

export default useCustomMutationReactQuery