
import api_list from "@/constant/apiUrl";
import app_config from "@/constant/config";
import { startRequest } from "@/utility/helper/requestHelper";

export const loginProcess = async (phone_number:Number) => {
  const apiData = {
    url: api_list.login,
    method: "POST",
    data: {
      wa_number: phone_number,
      role: 'user'
    }
  };

  return startRequest(apiData);
};

export const otpProcess = async (data:any) => {
  const apiData = {
    url: api_list.verifyOtp,
    method: "POST",
    data: {
      whatsapp_number: data.phoneNumber,
      whatsapp_token: data.otp
    }
  };

  return startRequest(apiData);
};

export const getUserDetail = async (data:any) => {
  const apiData = {
    url: api_list.getDetailUser,
    method: "GET",
    headers: {
      token: data.token
    },
  };

  return startRequest(apiData);
};